import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../store";
import User from "models/User";
import consts from "utils/consts";

interface AppReduxState {
  user?: User;
  token?: string;
  expiresAt?: number;
}

const initialState: AppReduxState = {};

const appStateSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setUser(
      _,
      action: PayloadAction<{ user: User; token: string; expiresAt: number }>
    ) {
      if (action.payload.user.address === consts.issuerAddress) {
        action.payload.user.type = "issuer";
        action.payload.user.name += " (Issuer)";
      }

      return action.payload;
    },
    removeUser() {
      return {};
    },
  },
});

export const { setUser, removeUser } = appStateSlice.actions;
export const userSelector = (state: RootState) => state.user.user;

export default appStateSlice;
